<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="8" lg="10" md="10" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Fonction digitale" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <DetailViewBoxComponent title="Détail de la fonction digitale">
          <template v-slot:items>
            <InputFieldComponent label="nom digital" :value="digitalName" />
            <InputFieldComponent label="service" :value="service" />
            <InputFieldComponent label="définition" :value="definition" />
            <InputFieldComponent label="description" :value="description" />
            <InputFieldComponent label="fonction support" :value="support" />
            <InputFieldComponent label="id drive" :value="idDrive" />

            <!-- Les rubriques associées -->
            <InputFieldComponent label="rubriques spécifiques" alignLabel="start">
              <template v-slot:value>
                <div v-for="(rubric, i) in specificRubrics" :key="i">
                  <v-row no-gutters align="center" justify="space-between" class="mb-1">
                    <v-col cols="5" >
                      <div>
                        {{ rubric.name }}
                      </div>
                    </v-col>
                    <v-col cols="2" class="px-2">
                      <div>
                        {{ rubric.context }}
                      </div>
                    </v-col>
                    <v-col cols="56">
                      <div>
                        {{ rubric.idDrive }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>

            <!-- Les rubriques globales -->
            <InputFieldComponent label="rubriques globales" alignLabel="start" :divider="false">
              <template v-slot:value>
                <div v-for="(grubric, i) in globalRubrics" :key="i+1">
                  <v-row no-gutters align="center" justify="space-between" class="mb-1">
                    <v-col cols="5">
                      <div>
                        {{ grubric.name }}
                      </div>
                    </v-col>

                    <v-col cols="2" class="px-2">
                      <div>
                        {{ grubric.context }}
                      </div>
                    </v-col>

                    <v-col cols="5">
                      <div>
                        {{ grubric.idDrive }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { FonctionService } from "@/service/sfr/function_service.js";
import ServicesService from "@/service/sfr/services_service.js";
import RubricService from "@/service/sfr/rubrics_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import * as logger from "@/tools/logger.js";


export default {
  name: "DetailFunction",

  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent,
    InputFieldComponent, },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceFunction: null,
      serviceServices: null,
      serviceRubrics: null,

      /**l'identifiant de la fonction à visualiser. */
      functionId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /** le nom digital */
      digitalName: null,
      /** le service dont dépend la fonction */
      service: null,
      /** le définition de la fonction */
      definition: null,
      /** le description de la fonction */
      description: null,
      /** la fonction est-elle support */
      support: null,
      /** l'id drive de la fonction */
      idDrive: null,
      /** les rubriques associées */
      specificRubrics: [],
      /** les rubriques globales */
      globalRubrics: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de la fonction
        let func = await this.serviceFunction.getFunctionById(this.functionId);
        logger.debug("fonction récupérée", func);
        
        // Récupération du service associé à la fonction
        let serv = await this.serviceServices.getServiceById(func.serviceId);

        // Init des datas
        this.digitalName = func.name || '-';
        this.service = serv.name || '-';
        this.definition = func.definition || '-';
        this.description = func.description || '-';
        this.idDrive = func.idDrive || '-';
        this.support = func.isSupport ? "oui" : "non";

        // Récupération de toutes les rubriques associées à la fonction
        let associateRubrics = await this.serviceFunction.getSpecificRubricsForFunction(this.functionId);
        // Récupération de toutes les spécialisations des rubriques globales
        let globalRubrics = await this.serviceFunction.getGlobalRubricsForFunction(this.functionId);

        // Parcours les rubriques associés
        for (let ar of associateRubrics) {

          let rubric = {};
          rubric.id = ar.id;
          rubric.name = ar.name;
          rubric.idDrive = ar.idDrive;

          /** Commenté pour affichage du tuple "rien" */
          // if (ar.context == "rien") {
          //   rubric.context = "";
          // } else {
          //   rubric.context = ar.context;
          // }
          //////////////////////////////////////////////
          rubric.context = ar.context;
          

          this.specificRubrics.push(rubric);
        }

        // Parcours les rubriques globales
        for (let gr of globalRubrics) {

          let rubric = {};
          rubric.id = gr.id;
          rubric.name = gr.name;
          rubric.idDrive = gr.idDrive;

          /** Commenté pour affichage du tuple "rien" */
          // if (gr.context == "rien") {
          //   rubric.context = "";
          // } else {
          //   rubric.context = gr.context;
          // }
          /////////////////////////////////////////////
          rubric.context = gr.context;

          this.globalRubrics.push(rubric);
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceServices = new ServicesService(this.$api);
    this.serviceRubrics = new RubricService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.functionId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>